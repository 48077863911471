<template>
    <div class="friend-item">
        <!-- 新用户的一些信息，用户名等 -->
        <div :class="['friend-item-user']">
            <div class="friend-item-user-info">
                <p class="friend-item-user-img">
                    <img :src=" itemStep.img ? itemStep.img : 'https://cdn.9kd.com/kdnet/user-fission/user_fission_tx.png'" alt="">
                </p>
                <p class="friend-item-user-name">{{itemStep.nickname}}</p>
            </div>
            <p class="friend-item-user-money" v-if="isdialog">待到账 {{itemStep.waitPay}}元</p>
            <p class="friend-item-user-money" v-if="!isdialog && (itemStep.proStatus == 0 || itemStep.proStatus == 1)">待到账 {{itemStep.waitPay}} 元</p>
            <div v-if="!isdialog && (itemStep.proStatus == 2 || itemStep.proStatus == 3)">
                <span class="all-finish-btn" v-if="itemStep.proStatus == 2">已全部完成</span>
                <span class="break-finish-btn" v-else-if="itemStep.proStatus == 3">已中断</span>
            </div>
        </div>
        <!-- 进度 -->
        <div class="friend-progress">
            <div :class="['friend-progress-step',{'first':item == 1,'last':item == 7}]" v-for="(item,index) in 7" :key="index">
                <div class="friend-progress-img">
                    <img v-if="item == 1" class="one" :src="itemStep.checkDay >= 1 ? isOpen : noOpen" alt="">
                    <img v-if="item == 2" class="two" :src="itemStep.checkDay >= 2 ? isOpen : noOpen" alt="">
                    <img v-if="item == 7" class="three" :src="itemStep.checkDay >= 3 ? isOpen : sevenNoOpen" alt="">
                </div>
                <div :class="['friend-progress-line',
                    {
                        'no-bg':item == 7,
                        'finish-line':(itemStep.proStatus == 0 
                        ? (itemStep.sort > item+1) 
                        : itemStep.proStatus == 1 ? (itemStep.sort >= item+1)
                        : itemStep.proStatus == 3 ? (itemStep.sort > item) 
                        : '') 
                        || itemStep.proStatus == 2
                    }
                ]">
                    <div :class="['friend-progress-circular',
                        {
                            'finished':(itemStep.proStatus == 0 
                            ? (itemStep.sort > item) 
                            : itemStep.proStatus == 1 ? (itemStep.sort >= item)
                            : itemStep.proStatus == 3 ? (itemStep.sort >= item)
                            : '') 
                            || itemStep.proStatus == 2
                        }
                    ]"></div>
                </div>
                <div class="friend-progress-num">{{item}}</div>
            </div>
        </div>

        <!-- 今日进度未完成 -->
        <div :class="['friend-task']" v-if="itemStep.proStatus == 0">
            <div class="friend-task-pro">
                <p class="friend-task-day">今日进度（第{{itemStep.sort}}天）</p>
                <ul class="friend-task-list">
                    <li :class="[{'color-deep':itemStep.login}]">
                        <span class="iconfont">{{itemStep.login ? '&#xe666;': '&#xe741;'}}</span> 已登录
                    </li>
                    <li :class="[{'color-deep':itemStep.read}]">
                        <span class="iconfont">{{itemStep.read ? '&#xe666;': '&#xe741;'}}</span> {{ itemStep.sort == 1 ? '已绑定手机号' : '已阅读内容'}}
                    </li>
                </ul>
            </div>
            <div class="friend-task-tips">
                <button v-if="!isdialog" :class="['friend-remind-btn',{'is-receive': itemStep.price == 0, 'is-invalid': itemStep.price == -1 || itemStep.price == -2}]" @click="receivedReward(itemStep)">{{itemStep.price == 0  ? '奖励已领取' : itemStep.price == -1 ? '奖励已失效' : '领取奖励'}}</button>
                <button :class="['friend-remind-btn',{'height-30':isdialog}]" v-if="!itemStep.login || !itemStep.read" @click="remind()">提醒TA</button>
                <p class="friend-disabled-time" v-if="itemStep.price != -1">{{itemStep.invalidTime}}后失效</p>
            </div>
        </div>

        <!-- 今日进度已完成 -->
        <div :class="['friend-today-finish',{'small-size':isdialog}]" v-if="itemStep.proStatus == 1">
            <p class="finish-p">今日进度已完成！</p>
            <p class="finish-p">明天记得提醒ta登录哦</p>
            <button :class="['recived-btn',{'is-receive': itemStep.price == 0, 'is-invalid': itemStep.price == -1}]" v-if="!isdialog && (itemStep.proStatus == 0 || itemStep.proStatus == 1)" @click="receivedReward(itemStep)">{{itemStep.price == 0  ? '奖励已领取' : itemStep.price == -1 ? '奖励已失效' : '领取奖励'}}</button>
            <div class="friend-close-box" v-if="isdialog">
                Tips：记得领取奖励，10天内不领取将失效哦
            </div>
        </div>

        <!-- 全部进度已完成 -->
        <div :class="['friend-today-finish',{'small-size':isdialog}]" v-if="itemStep.proStatus == 2">
            <button :class="['recived-btn',{'is-receive':itemStep.price == 0, 'is-invalid': itemStep.price == -1}]" @click="receivedReward(itemStep)">{{itemStep.price == 0  ? '奖励已领取' : itemStep.price == -1 ? '奖励已失效' : '领取奖励'}}</button>
            <p class="friend-price-time" v-if="itemStep.price != -1 && itemStep.price != 0">{{itemStep.invalidTime}}后奖励失效</p>
        </div>

        <!-- 进度中断 -->
        <div :class="['friend-today-finish',{'small-size':isdialog}]" v-if="itemStep.proStatus == 3">
            <button :class="['recived-btn',{'is-invalid': itemStep.price == -1 || itemStep.price == 0}]" @click="receivedReward(itemStep)">{{itemStep.price == 0  ? '暂无奖励' : itemStep.price == -1 ? '奖励已失效' : '领取奖励'}}</button>
            <p class="friend-price-time" v-if="itemStep.price != -1 && itemStep.price != 0">{{itemStep.invalidTime}}后奖励失效</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen:'https://cdn.9kd.com/kdnet/user-fission/user_fission_red_paper_1.png',
            noOpen:'https://cdn.9kd.com/kdnet/user-fission/user_fission_red_paper_2.png',
            sevenNoOpen:'https://cdn.9kd.com/kdnet/user-fission/user_fission_red_paper_3.png'
        }
    },
    props:{
        itemStep:{      // 进度数据
            type:[Object]
        },
        isdialog:{      // 是否用弹框的形式展示
            type:Boolean,
            default:false
        }
    },
    methods: {
        /**
         * 关闭弹框函数
         */
        closeComfirm(){
            this.$emit('closeComfirm',false)
        },
        /**
         * 提醒TA函数
         */
        remind(){
            this.$emit('remind')
        },
        /**
         * 领取奖励函数
         */
        receivedReward(data){
            this.$emit('receivedReward',data)
        }
    },
}
</script>

<style lang="less">
    .padding-ten{
        padding: 8px 0;
    }
    .friend-item{
        margin-bottom: 10px;
        border-radius: 5px;
        background: #FFECE5;
    }
    .friend-item-user{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;
    }
    .friend-item-user-info{
        display: flex;
        align-items: center;
        padding: 10px 0 10px 0px;
        font-size: 13px;
        color: #666666;
        .friend-item-user-img{
            overflow: hidden;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .friend-item-user-name{
            margin-left: 5px;
        }
    }
    .friend-item-user-money{
        color: #E52218;
        font-size: 13px;
        font-weight: bold;
    }
    .friend-item-tip-box{
        .iconfont{
            font-size: 12px;
        }
    }
    .all-finish-btn,.break-finish-btn{
        color: #E52218;
        font-size: 13px;
        font-weight: bold;
    }
    .friend-progress{
        display: flex;
        padding: 10px 15px;
        .friend-progress-step{
            width: 15%;
            &.first{
                width: 22%;
            }
            &.last{
                width: 5%;
                .friend-progress-num{
                    color: #E52218;
                    font-weight: bold;
                }
            }
            &.left{
                width: 5%;
            }
            .friend-progress-line{
                position: relative;
                height: 4px;
                background: #FFDACF;
                .friend-progress-circular{
                    position: absolute;
                    left: -9px;
                    top: -14px;
                    box-sizing: border-box;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFDACF;
                    transform: scale(.5);
                    &.finished{
                        background: url(https://cdn.9kd.com/kdnet/user-fission/user_fission_finished.png) center no-repeat;
                        background-size: 100%;
                    }
                }
            }
            .finish-line{
                box-shadow: inset 0 0 0 1px #ffdacf;
                background: linear-gradient(0deg, #F5CA9C, #F11B1F, #F47056,#F5CA9C);
            }
            .no-bg{
                background: none;
            }
            .friend-progress-img{
                height: 40px;
                img{
                    height: 65%;
                }
                .two{
                    margin-left: -1px;
                }
                .three{
                    margin-left: -12px;
                }
            }
            .friend-progress-num{
                padding: 10px 0 0 2px;
                color: #777777;
            }
        }
    }
    .friend-task{
        display: flex;
        padding: 6px 0 6px 15px;
        .friend-task-pro{
            box-sizing: border-box;
            width: 60%;
            padding: 10px 10px 0 10px;
            border-radius: 5px;
            background: #FFDACF;
        }
        .friend-task-list{
            li{
                display: flex;
                height: 25px;
                margin-right: 10px;
                font-size: 13px;
                color: rgba(229,34,24,0.5);
                .iconfont{
                    margin-right: 5px;
                }
            }
            .color-deep{
                color: rgba(229,34,24,1);
            }
        }
        .friend-task-day{
            height: 27px;
            color: #E52218;
            font-size: 13px;
        }
        .friend-task-tips{
            width: 40%;
            .friend-remind-btn{
                display: block;
                width: 80%;
                height: 28px;
                margin: 0 auto 10px auto;
                background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
                color: white;
                border: none;
                border-radius: 15px;
                font-size: 13px;
            }
            .height-30{
                height: 30px;
                margin-top: 15px;
            }
            .is-receive{
                background: #D7D7D7;
            }
            .is-invalid{
                background: #D7D7D7;
                color: #999999;
            }
            .friend-disabled-time{
                font-size: 11px;
                color: #999999;
                text-align: center;
            }
        }
        &.display-block{
            display: block;
            .friend-task-day{
                height: 26px;
                font-size: 14px;
            }
            .friend-task-tips{
                padding: 20px 0;
                text-align: center;
            }
            .friend-remind-btn{
                padding: 3px 10px;
                font-size: 14px;
            }
            .friend-disabled-time{
                margin-top: 5px;
                color: black;
            }
        }
    }
    .friend-today-finish{
        text-align: center;
        padding: 6px 0;
        p{
            height: 23px;
        }
        .finish-p{
            height: 30px;
            color: #E52218;
            font-size: 15px;
            font-weight: 500;
        }
        .friend-price-time{
            font-size: 12px;
            color: #999;
        }
        .recived-btn{
            display: block;
            width: 100px;
            height: 30px;
            margin: 0 auto 10px auto;
            background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
            color: white;
            border: none;
            border-radius: 15px;
            font-size: 13px;
        }
        .is-receive{
            background: #D7D7D7;
        }
        .is-invalid{
            background: #D7D7D7;
            color: #999999;
        }
        .friend-close-box{
            font-size: 10px;
            color: #999999;
        }
    }
    .small-size{
        p{
            font-size: 13px;
        }
        
    }
</style>
